#home {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media only screen and (max-width: 575px) {
  #home {
    align-items: stretch;
  }
}

#site-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #F8F8F8;
  border: none;
  border-radius: 0.4rem;
  box-shadow: -4px 4px 4px #26282B;
  width: 40rem;
  min-height: 10rem;
  max-height: 10rem;
  margin-top: 7rem;
  margin-bottom: 7rem;
  font-size: 4rem;
  font-weight: 900;
}
@media only screen and (max-width: 575px) {
  #site-title {
    width: 100%;
    min-height: 7rem;
    max-height: 7rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    font-size: 2.8rem;
    font-weight: 900;
  }
}