#about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #F8F8F8;
  box-shadow: -4px 4px 4px #26282B;
  border-radius: 0.4rem;
  box-sizing: border-box;
  width: 40rem;
  margin-top: 7rem;
  margin-bottom: 7rem;
  padding: 1rem;
}
@media only screen and (max-width: 575px) {
  #about-container {
    width: 100%;
    margin-top: 4rem;
    margin-top: 4rem;
  }
}

#about-title {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

#about-quote-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.1rem;
}

#about-quote-opening-quotation, #about-quote-closing-quotation {
  height: 14px;
}

#about-quote-closing-quotation {
  margin-left: auto;
}

#about-quote {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

#about-quote-attribution {
  margin-left: auto;
}

#about-body {
  margin-top: 0.3rem;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}
#about-body > p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-indent: 0.8rem;
}