#suggestion-menu-container {
  background: #F8F8F8;
  border: none;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  box-shadow: -4px 4px 4px #26282B;
  clip: rect(0px, 100000px, 100000px, -100000px);
  max-height: 3000px;
  position: absolute;
  z-index: 2;
  overflow: hidden;
  -moz-transition: padding 0.4s linear, max-height 0.4s linear;
  -o-transition: padding 0.4s linear, max-height 0.4s linear;
  -webkit-transition: padding 0.4s linear, max-height 0.4s linear;
  transition: padding 0.4s linear, max-height 0.4s linear;
}
#suggestion-menu-container.hidden {
  display: none;
  max-height: 0px;
}

@media only screen and (max-width: 575px) {
  #movie-title-field.focused > #suggestion-menu-container {
    box-shadow: -4px 4px 8px 4px #26282B;
  }
}
#suggestion-menu {
  overflow: auto;
  padding-bottom: 1rem;
}

.suggestion {
  white-space: nowrap;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.suggestion:hover {
  color: #F8F8F8;
  background-color: #385269;
}