#start-survey-button {
  width: 10rem;
  min-height: 2.25rem;
  max-height: 2.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media only screen and (max-width: 575px) {
  #start-survey-button {
    width: 100%;
  }
}
#start-survey-button:disabled {
  background-color: #c5c5c5;
  color: #565a61;
}