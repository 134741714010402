#movie-title-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40rem;
}
@media only screen and (max-width: 575px) {
  #movie-title-form {
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    width: 100%;
  }
}

#movie-title-field, #movie-title-field-placeholder {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  position: relative;
  min-height: 2.25rem;
  max-height: 2.25rem;
  margin-right: 2rem;
}
@media only screen and (max-width: 575px) {
  #movie-title-field, #movie-title-field-placeholder {
    margin-right: 0rem;
    margin-bottom: 1rem;
  }
}

#movie-title-field {
  background: #F8F8F8;
  border: none;
  border-radius: 0.4rem;
  box-shadow: -4px 4px 4px #26282B;
  -moz-transition: top 0.4s, width 0.4s, box-shadow 0.4s;
  -o-transition: top 0.4s, width 0.4s, box-shadow 0.4s;
  -webkit-transition: top 0.4s, width 0.4s, box-shadow 0.4s;
  transition: top 0.4s, width 0.4s, box-shadow 0.4s;
}
#movie-title-field.focused {
  box-shadow: -4px 4px 8px 4px #26282B;
}

#movie-title-field-placeholder {
  display: none;
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
  z-index: -1;
}

#movie-title-field.floating ~ #movie-title-field-placeholder {
  display: inline;
}

#movie-title-input-container {
  flex: 1;
}

#movie-title-input {
  box-shadow: none;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 575px) {
  #movie-title-field.focused #movie-title-input {
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
  }
}

#movie-title-input-clear {
  display: none;
  color: #888d95;
  border-bottom-left-radius: 0rem;
  border-top-left-radius: 0rem;
  box-shadow: none;
  width: 2.25rem;
  font-size: 16px;
}

#movie-title-field.focused > #movie-title-input-clear {
  display: flex;
  justify-content: center;
  align-items: center;
}