#restart-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
}
@media only screen and (max-width: 575px) {
  #restart-btn {
    top: 0.5rem;
    left: 0.5rem;
    font-size: 1.5rem;
  }
}

#restart-btn-text {
  margin-left: 0.5rem;
}
@media only screen and (max-width: 575px) {
  #restart-btn-text {
    display: none;
  }
}